.root {
	position: sticky !important;
	top: 0px !important;
	width: 100% !important;
	z-index: 500;
	:global {
		.logo {
			height: 32px;
		}
		.selected {
			color: black !important;
		}
	}
	
}
.blue_nav {
	.nav_link{
		color: white !important;
	}
	.nav_link:hover{
		color: orange !important;
	}
	:global {
		.user-type-block{
			padding: 0.15rem 0.5rem;
			border: 2px solid white;
			border-radius: 6px;
			width: 56.2px;
			.user-type{
				color: white;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}
.white_nav {
	.nav_link:hover {
		color: black;
	}
	.nav_link:hover {
		color: black !important;
		font-weight: 500;
	}
	:global {
		.user-type-block{
			padding: 0.15rem 0.5rem;
			border: 2px solid black;
			border-radius: 6px;
			width: 56.2px;
			.user-type{
				font-size: 14px;
				color: black;
				font-weight: 600;
			}
		}
	}
}
.sign_up {
	background-color: orange !important;
    color: white !important;
    border-radius: 4px !important;
    border: none;
	display: inline-block;
	font-weight: 600 !important;
}
.sign_up:hover {
	font-weight: 600 !important;
	color: black !important;
}
