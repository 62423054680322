.CustomCheckBox{
    position: relative;
    width: 16px;
    height: 16px;
    .checkbox {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        z-index: 2;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        width: 16px;
        height: 16px;
        border-radius: 2px;
    }
    .checkbox ~ .custom-overlay {
        width: 16px;
        height: 16px;
        border: solid 1px #e2e6ee;
        background-color: #fbfbfc;
        display: inline-block;
        background-color: transparent;
        background-image: none;
    }
    .checkbox:checked ~ .custom-overlay {
        width: 16px;
        height: 16px;
        background-color: #ffffff;
        border: solid 1px #e2e6ee;
        background-image: url('/images/check.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
    }
}