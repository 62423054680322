@import "./roots.scss";

body {
	background-color: $body-bg;
	font-family: 'Roboto';
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
	color: #1f273c;
  }
.body-bg {
	background-color: $body-bg;
}
.blue-bg {
	background-color: $blue-color;
}
.blue-bg-2 {
	background-color: $blue-color-2;
}
.orange-bg {
	background-color: orange;
}
.red-bg {
	background-color: $red-color;
}
.black-color-2 {
	color: $black-color-2;
}
.blue-color-2 {
	color: $blue-color-2;
}
.hover-blue {
	color: $blue-color-2;
}
.hover-blue:hover {
	color: $blue-color;
}
.pointer {
	cursor: pointer;
}
.border-r-4 {
	border-radius: 4px;
}
.font-s-13 {
	font-size: 13px;
}
.font-s-14 {
	font-size: 14px;
}
.font-s-16 {
	font-size: 1rem;
}
.blurred-box{
	filter:blur(4px);
	pointer-events: none;
	user-select: none;
}
.border-grey {
	border: 1px solid #e2e6ee;
}

.add-required::after{
	content: '*';
	position: absolute;
	top: 5px;
	left: 46px;
	color: #f00
}
.disable-block {
	pointer-events: none;
    opacity: 0.7;
}
@media(max-width: 767px) {
	.widget {
		max-width: 100% !important;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1200px; 
	}
  }
.remove-btn-style {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: none !important;
}

.tox-tinymce {
	// display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}