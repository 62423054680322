.date-input {
    width: 210px;
}
.time-input {
    width: 150px;
}
.date-input, .time-input {
    padding-left: 15px;
    padding-right: 15px;
}
.blue-background {
    background-color: #b4d7ffa8;
}