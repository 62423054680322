.root {
	:global {
		
	}
}
.rectangle {
	height: auto;
	box-shadow: 0 5px 10px 0 rgba(41, 74, 111, 0.07);
	background-color: #ffffff;
}
.login_btn {
	height: 49px;
	border-radius: 4px 4px 0px 0px;
	background-color: #1161be;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	color: #ffffff;
	display: flex;
}
.form_block {
	border-left: solid 1px #e2e6ee;
	border-right: solid 1px #e2e6ee;
	border-bottom: solid 1px #e2e6ee;
}