@import url(https://fonts.googleapis.com/css?family=Roboto|Archivo);
@import url(https://fonts.googleapis.com/css?family=Quicksand:700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header_root__jsaeH{position:-webkit-sticky !important;position:sticky !important;top:0px !important;width:100% !important;z-index:500}.Header_root__jsaeH .logo{height:32px}.Header_root__jsaeH .selected{color:black !important}.Header_blue_nav__cXO5a .Header_nav_link__14j6p{color:white !important}.Header_blue_nav__cXO5a .Header_nav_link__14j6p:hover{color:orange !important}.Header_blue_nav__cXO5a .user-type-block{padding:0.15rem 0.5rem;border:2px solid white;border-radius:6px;width:56.2px}.Header_blue_nav__cXO5a .user-type-block .user-type{color:white;font-size:14px;font-weight:600}.Header_white_nav__X_vS0 .Header_nav_link__14j6p:hover{color:black}.Header_white_nav__X_vS0 .Header_nav_link__14j6p:hover{color:black !important;font-weight:500}.Header_white_nav__X_vS0 .user-type-block{padding:0.15rem 0.5rem;border:2px solid black;border-radius:6px;width:56.2px}.Header_white_nav__X_vS0 .user-type-block .user-type{font-size:14px;color:black;font-weight:600}.Header_sign_up__3RJjs{background-color:orange !important;color:white !important;border-radius:4px !important;border:none;display:inline-block;font-weight:600 !important}.Header_sign_up__3RJjs:hover{font-weight:600 !important;color:black !important}

.AdvancedFilterPc_advanced_form__3ifhu{z-index:5}.AdvancedFilterPc_add__3bnyJ{color:#1b7beb}.AdvancedFilterPc_add_icon__1FWHp,.AdvancedFilterPc_clear_icon__Dcxpf{content:' ';height:24px;width:24px;border-radius:50%;display:inline-block;background-repeat:no-repeat;background-position:50% 50%}.AdvancedFilterPc_add_icon__1FWHp{background-image:url(/images/add.svg);border:1px solid #2c85ec}.AdvancedFilterPc_clear_icon__Dcxpf{background-image:url(/images/remove.svg);border:1px solid #e2e6ee}

.style_root__2VxlT .height-121{height:121px}.style_root__2VxlT .search-box{box-shadow:0 5px 10px 0 rgba(9,52,103,0.13);height:40px}.style_root__2VxlT .image-wrapper::before{content:url("/images/zoom.svg");position:absolute;top:12px;left:24px}.style_root__2VxlT .form-control{margin-left:30px;border:none;box-shadow:none}.style_root__2VxlT .form-control:focus{border:unset;outline:0;box-shadow:none}.style_root__2VxlT .search{height:34px;margin:3px}.style_root__2VxlT .tag{border:1px solid #a1c0e4;background-color:transparent;background-color:initial;height:40px}.style_root__2VxlT .background{background-image:url(/images/search-field-pattern.svg)}

.Table_root__26efc thead tr .sort::after{content:url("/images/select.svg");position:relative;top:2px;right:-10px;display:inline-block;width:9px;height:16px}.Table_root__26efc thead tr .sort.asc::after{content:url("/images/select-asc.svg")}.Table_root__26efc tbody tr{border-top:1px solid #e2e6ee}.Table_root__26efc tbody tr th{font-weight:500}

.Spinner_root__32SLa{position:absolute;top:40%;width:100%;left:0px}.Spinner_root__32SLa .spinner-border{color:#1b7beb;height:50px;width:50px}

.Breadcrumbs .breadcrumb{padding:10px 0px 10px 0px;background-color:transparent;margin:0px}.Breadcrumbs .breadcrumb-item+.breadcrumb-item::before{content:url("/images/small-down-copy-11.svg")}.Breadcrumbs .breadcrumb-item.active{color:#1f273c}.Breadcrumbs a,.Breadcrumbs .breadcrumb a{text-decoration:none;color:#1b7beb}.Breadcrumbs .active{color:#1f273c}

.Login_rectangle__2IiEg{height:auto;box-shadow:0 5px 10px 0 rgba(41,74,111,0.07);background-color:#ffffff}.Login_login_btn__37yH9{height:49px;border-radius:4px 4px 0px 0px;background-color:#1161be;font-size:16px;font-weight:500;font-style:normal;font-stretch:normal;line-height:1;letter-spacing:normal;color:#ffffff;display:flex}.Login_form_block__3utcM{border-left:solid 1px #e2e6ee;border-right:solid 1px #e2e6ee;border-bottom:solid 1px #e2e6ee}

.languageSelect_root__3XPec .react-select__control{height:40px;border-radius:4px;box-shadow:0 1px 2px 0 rgba(101,114,148,0.08);border:solid 1px #e3e7ef;border-radius:0.5rem !important;background-color:#ffffff;padding:0 16px}.languageSelect_root__3XPec .react-select__control--menu-is-open{background-color:#ffffff;border-radius:4px 4px 0 0}.languageSelect_root__3XPec .react-select__value-container,.languageSelect_root__3XPec .react-select__single-value{padding:0;font-family:Roboto;font-size:14px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.14;letter-spacing:normal;color:#1f273c}.languageSelect_root__3XPec .react-select__placeholder{font-family:Roboto;font-size:14px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.71;letter-spacing:normal;color:#3f4d6e}.languageSelect_root__3XPec .react-select__menu{width:100%;margin-top:0;border-radius:0 0 4px 4px;box-shadow:0 1px 2px 0 rgba(101,114,148,0.08);border:solid 1px #e3e7ef;background-color:#ffffff;border-top:0}.languageSelect_root__3XPec .option{padding:7px 16px;cursor:pointer}.languageSelect_root__3XPec .option .label{margin-bottom:2px;font-family:Roboto;font-size:14px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.14;letter-spacing:normal;color:#1f273c}.languageSelect_root__3XPec .option .long-label{opacity:0.5;font-family:Roboto;font-size:9px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.78;letter-spacing:normal;color:#1f273c}.languageSelect_root__3XPec .option:hover,.languageSelect_root__3XPec .option:active{background-color:#f2f5fb}

.CustomCheckBox{position:relative;width:16px;height:16px}.CustomCheckBox .checkbox{cursor:pointer;position:absolute;display:inline-block;z-index:2;top:0;left:0;margin:0;opacity:0;width:16px;height:16px;border-radius:2px}.CustomCheckBox .checkbox ~ .custom-overlay{width:16px;height:16px;border:solid 1px #e2e6ee;background-color:#fbfbfc;display:inline-block;background-color:transparent;background-image:none}.CustomCheckBox .checkbox:checked ~ .custom-overlay{width:16px;height:16px;background-color:#ffffff;border:solid 1px #e2e6ee;background-image:url("/images/check.svg");background-repeat:no-repeat;background-size:contain;background-position:center right}

.AddEvent_date-input__1LYM8{width:210px}.AddEvent_time-input__1kyFY{width:150px}.AddEvent_date-input__1LYM8,.AddEvent_time-input__1kyFY{padding-left:15px;padding-right:15px}.AddEvent_blue-background__FFvp_{background-color:#b4d7ffa8}

body{background-color:#fafafc;font-family:'Roboto';font-size:14px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:1.14;letter-spacing:normal;color:#1f273c}.body-bg{background-color:#fafafc}.blue-bg{background-color:#1161be}.blue-bg-2{background-color:#1b7beb}.orange-bg{background-color:orange}.red-bg{background-color:#dc3545}.black-color-2{color:#3f4d6e}.blue-color-2{color:#1b7beb}.hover-blue{color:#1b7beb}.hover-blue:hover{color:#1161be}.pointer{cursor:pointer}.border-r-4{border-radius:4px}.font-s-13{font-size:13px}.font-s-14{font-size:14px}.font-s-16{font-size:1rem}.blurred-box{-webkit-filter:blur(4px);filter:blur(4px);pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.border-grey{border:1px solid #e2e6ee}.add-required::after{content:'*';position:absolute;top:5px;left:46px;color:#f00}.disable-block{pointer-events:none;opacity:0.7}@media (max-width: 767px){.widget{max-width:100% !important}}@media (min-width: 1200px){.container{max-width:1200px}}.remove-btn-style{background:none;color:inherit;border:none;padding:0;font:inherit;cursor:pointer;outline:none !important}.tox-tinymce{width:100% !important;padding:0.375rem 0.75rem !important;font-size:1rem !important;font-weight:400 !important;line-height:1.5 !important;color:#495057 !important;background-color:#fff !important;background-clip:padding-box !important;border:1px solid #ced4da;border-radius:0.25rem !important;transition:border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important}

