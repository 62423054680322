.root {
	:global {
		thead {
			tr {
				.sort::after {
					content: url('/images/select.svg');
					position: relative;
					top: 2px;
					right: -10px;
					display: inline-block;
					width: 9px;
					height: 16px;
				}
				.sort.asc::after {
					content: url('/images/select-asc.svg');
				}
			}
		}
		tbody {
			tr {
				border-top: 1px solid #e2e6ee;
				th {
					font-weight: 500;
				}
			}
		}
	}
}
