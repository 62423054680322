.advanced_form {
	z-index: 5;
}
.add {
	color: #1b7beb;
}
.add_icon, .clear_icon {
	content: ' ';
	height: 24px;
	width: 24px;
	border-radius: 50%;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.add_icon {
	background-image: url(/images/add.svg);
	border: 1px solid #2c85ec
}
.clear_icon {
	background-image: url(/images/remove.svg);
	border: 1px solid #e2e6ee;
}