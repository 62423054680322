.root {
	position: absolute;
	top: 40%;
	width: 100%;
	left: 0px;
	:global {
		.spinner-border{
			color:#1b7beb;
			height: 50px;
			width: 50px;
		}
	}
}